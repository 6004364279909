@import ../../../styles/helpers


.actions
    margin-top: 16px
    padding: 32px
    border-radius: 8px
    background: $n8
    +t
        margin-top: 56px
        padding: 0
        background: none
    +dark
        background: #18191D
        +t
            background: none
        
.title
    margin-bottom: 24px
    +body-bold-1

.maintitle
    margin-bottom: 24px
    font-weight: 600
    font-size: 36px
    border-bottom: 1px solid $n6
    span:nth-child(1)
        color: $p4
    span:nth-child(2)
        color: $p3
    +m
        font-size: 32px
        line-height: (32/24)


.info
    font-weight: bold !important
    margin-bottom: 12px

.text
    color: $n4

.info
    +body-bold-2

.list
    margin-bottom: 32px

.line
    display: flex
    flex-wrap: wrap
    align-items: center
    &:not(:last-child)
        margin-bottom: 12px
        padding-bottom: 12px
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3

.subtitle
    margin-right: auto
    padding-right: 20px
    color: $n4

.details
    display: flex
    align-items: center

.content
    margin-right: 12px
    font-weight: 500

.copy
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $n2
            +dark
                fill: $n8

.code
    padding: 12px
    background: $p3
    color: white
    border-radius: 4px
    cursor: pointer
    text-align: center
    box-shadow: 0px 4px 8px -4px rgba(15, 15, 15, 0.1)
    transition: all .15s
    &:hover
        box-shadow: 10px 14px 15px -4px rgba(15, 15, 15, 0.3)

    +body-bold-2
    +dark
        background: $n3

.btns
    margin-top: 24px
    text-align: center
    .button
        min-width: 184px