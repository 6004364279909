@import ../../styles/helpers

.submitbutton
    margin-top: 16px
    height: 40px
    background: $p1
    border-radius: 20px
    padding: 0 16px
    font-size: 14px
    color: $n8
    box-shadow: 0 0 0 2px #E6E8EC inset