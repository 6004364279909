@import ../../../styles/helpers

.stage
    +caption-2
    color: $n3
    +m
        margin-bottom: 12px
    +dark
        color: $n6
.card
    max-width: 1000px
    margin: 40px auto
    padding: 32px
    border-radius: 16px
    +m
        border-radius: 0px
    color: $n2
    background: $n7
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    +d
        padding: 24px 24px
    +m
        width: 100%
        margin: 0
        padding: 16px 24px
    +dark
        color: $n8
        background: $n2

.title
    margin-bottom: 12px
    span:nth-child(1)
        color: $p4
    span:nth-child(2)
        color: $p3

.email
    display: flex
    align-items: center
    +body-bold-2
    +m
        margin-bottom: 32px
    svg
        margin-right: 12px
        fill: $n2
        +dark
            fill: $n8

.subtitle
    margin-bottom: 24px
    +body-bold-1

.info
    margin-bottom: 32px
    +body-2
    color: $n3
    +dark
        color: $n6

.row
    display: flex
    margin: 0 -8px
    +m
        display: block
        margin: 0

.field
    position: relative
    flex: 0 0 calc(50% - 16px)
    width: calc(50% - 16px)
    margin: 0 8px
    +m
        width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 16px

.button
    margin-top: 16px
    +m
        width: 100%

.link
    +button-2
    color: $n4
    margin-top: 32px !important
    transition: color .2s
    &:hover,
    &.active
        color: $n2
        +dark
            color: $n8