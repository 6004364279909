@import ../../styles/helpers

.sectionfull
    width: 100%
    padding: 80px 0 
    justify-content: center
    text-align: center
    margin-left: auto
    margin-right: auto
    background: $p4

.title
    color: $n8
    margin-bottom: 12px
    max-width: 700px
    text-align: center
    justify-content: center
    margin-left: auto
    margin-right: auto
    +m
        font-size: 36px

.info
    +body-2
    color: $n8
    margin-bottom: 32px
    +dark
        font-weight: 600

.marginHorizontalAuto
    box-shadow: 0px 4px 8px -4px rgba(15, 15, 15, 1.1)
    margin-left: auto
    margin-right: auto
    max-width: 800px
    margin-bottom: 20px
    +m
        margin-left: 8px
        margin-right: 8px

