@import ../../../styles/helpers

.actions
    margin-top: 16px
    padding: 32px
    border-radius: 8px
    background: $n8
    +t
        margin-top: 56px
        padding: 0
        background: none
    +dark
        background: #18191D
        +t
            background: none

.title
    margin-bottom: 24px
    +body-bold-1

.iframe 
    width: 100% 
    height: 140vh
    display: block
    overflow-y: hidden
    +m
        height: 100%
        min-height: 90vh
        max-height: 100vh
        overflow-y: auto


.maintitle
    margin-bottom: 24px
    font-weight: 600
    font-size: 36px
    border-bottom: 1px solid $n6
    span:nth-child(1)
        color: $p4
    span:nth-child(2)
        color: $p3
    +m
        font-size: 32px
        line-height: (32/24)

.text,
.info
    margin-bottom: 12px

.text
    color: $n4

.info
    font-weight: bold !important
    +body-bold-2

.list
    margin-bottom: 32px

.line
    display: flex
    flex-wrap: wrap
    align-items: center
    &:not(:last-child)
        margin-bottom: 12px
        padding-bottom: 12px
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3

.subtitle
    margin-right: auto
    padding-right: 20px
    color: $n4

.details
    display: flex
    align-items: center

.content
    margin-right: 12px
    font-weight: 500

.copy
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $n2
            +dark
                fill: $n8

.code
    padding: 12px
    background: $p3
    color: white
    border-radius: 4px
    cursor: pointer
    text-align: center
    box-shadow: 0px 4px 8px -4px rgba(15, 15, 15, 0.1)
    transition: all .15s
    &:hover
        box-shadow: 10px 14px 15px -4px rgba(15, 15, 15, 0.3)

    +body-bold-2
    +dark
        background: $n3

.btns
    margin-top: 24px
    text-align: center
    .button
        min-width: 184px



.loader,
.loaderWhite
    width: 1em
    height: 1em
    border-radius: 50%
    position: relative
    font-size: 4px
    animation: loader 1.1s infinite ease
    text-indent: -9999em
    transform: translateZ(0)



.loader
    animation: loader 1.1s infinite ease
    +dark
        animation-name: loader-white

.loaderWhite
    animation-name: loader-white


@keyframes loader 
    0%,
    100%
        box-shadow: 0em -2.6em 0em 0em #777e90, 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.5), -1.8em -1.8em 0 0em rgba(119,126,144, 0.7)
    12.5%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.7), 1.8em -1.8em 0 0em #777e90, 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.5)
    25%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.5), 1.8em -1.8em 0 0em rgba(119,126,144, 0.7), 2.5em 0em 0 0em #777e90, 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2)
    37.5%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.5), 2.5em 0em 0 0em rgba(119,126,144, 0.7), 1.75em 1.75em 0 0em #777e90, 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2)
    50%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.5), 1.75em 1.75em 0 0em rgba(119,126,144, 0.7), 0em 2.5em 0 0em #777e90, -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2)
    62.5%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.5), 0em 2.5em 0 0em rgba(119,126,144, 0.7), -1.8em 1.8em 0 0em #777e90, -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2)
    75%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.5), -1.8em 1.8em 0 0em rgba(119,126,144, 0.7), -2.6em 0em 0 0em #777e90, -1.8em -1.8em 0 0em rgba(119,126,144, 0.2)
    87.5%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.5), -2.6em 0em 0 0em rgba(119,126,144, 0.7), -1.8em -1.8em 0 0em #777e90

@keyframes loader-white
    0%,
    100%
        box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7)
    12.5% 
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5)
    25%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2)
    37.5%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2)
    50%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2)
    62.5%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2)
    75%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2)
    87.5%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff
