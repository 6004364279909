@import ../../styles/helpers

.main
    position: relative
.button
    margin-top: 48px
    +m
        margin-top: 32px


.container
    align-items: center
    min-height: 280px
    padding-top: 112px
    text-align: center
    padding-bottom: 112px
    +d
        padding-top: 80px
    +m
        display: block
        min-height: auto
        padding-top: 32px
        padding-bottom: 16px

.title
    margin-bottom: 20px
    text-align: center

.info
    font-size: 20px
    text-align: center
    +m
        font-size: 16px

.text
    margin-bottom: 24px
    +body-2
    color: $n4