@import ../../styles/helpers

.checkicon
    margin-right: 12px
    color: $p4
    font-size: 20px

.elementcheck
    display: flex
    align-items: center
    padding-right: 32px
    padding-top: 8px
    padding-bottom: 8px
    +dark
        border-color: $n3
    +m
        padding-right: 0
        border-right: none
        border-left: none
    +body-bold-2

.border-right
    border-right: 1px solid lightgrey
    +m
        border-right: none