@import ../../../styles/helpers

.stage
    +caption-1
    color: $n3
    +dark
        color: $n6

.title
    margin-bottom: 12px
    span:nth-child(1)
        color: $p4
    span:nth-child(2)
        color: $p3


.ppsrimage
    margin-left: 30px
    width: 100px
    +m
        width: 70px  
        margin-left: auto  

.email
    display: flex
    align-items: center
    +body-bold-2
    svg
        margin-right: 12px
        fill: $n2
        +dark
            fill: $n8
.checkicon
    margin-right: 12px
    color: $p4
    font-size: 20px



.elementcheck
    display: flex
    align-items: center
    padding-right: 32px
    padding-top: 8px
    padding-bottom: 8px
    border-right: 1px solid lightgrey
    +dark
        border-color: $n3
    +m
        padding-right: 0
        border-right: none
    +body-bold-2

.element
    display: flex
    align-items: center
    padding-right: 24px
    border-right: 1px solid lightgrey
    +dark
        border-color: $n3
    +m
        padding-right: 0
        border-right: none
    +body-bold-2

.item
    +dark
        border-color: $n3
    &:last-child
        .button
            +m
                width: 100%

.subtitle
    margin-bottom: 24px
    +body-bold-1

.info
    margin-bottom: 24px
    +body-2
    color: $n3
    +dark
        color: $n6

.group
    margin-bottom: 24px

.box
    position: relative
    .field
        position: relative
        padding-right: 36px
    .label
        color: $n2
        +dark
            color: $n4
    .input
        padding-right: 82px
        border-color: $n7
        background: $n7
        +dark
            background: $n2
            border-color: $n2
        &:focus
            border-color: $p4
            background: transparent
    &:not(:last-child)
        margin-bottom: 24px

.remove
    position: absolute
    right: 0
    bottom: 12px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $n2

.category
    position: absolute
    right: 48px
    bottom: 11px

.rowwrap
    flex-wrap: wrap
    display: flex

.row
    display: flex
    +m
        display: block
        margin: 0
    .field
        flex: 0 0 calc(50% - 16px)
        width: calc(50% - 16px)
        margin: 0 8px
        +m
            width: 100%
            margin: 0
            &:not(:last-child)
                margin-bottom: 16px
    &:not(:last-child)
        margin-bottom: 16px

.card
    max-width: 1000px
    margin: 40px auto
    padding: 32px
    border-radius: 16px
    +m
        border-radius: 0px
    color: $n2
    background: $n7
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    +d
        padding: 24px 24px
    +m
        width: 100%
        margin: 0
        padding: 16px 24px
    +dark
        color: $n8
        background: $n2


.value,
.input
    +dm-sans
    font-weight: 700
    font-size: 74px
    line-height: 1
    letter-spacing: -.02em
    color: $n2
    +m
        font-size: 60px
    +dark
        color: $n8

.value
    min-width: 72px
    max-width: 500px
    min-height: 96px
    padding-right: 5px
    opacity: 0
    +m
        min-height: 64px
        max-width: 280px
        padding-bottom: 10px

.label
    margin-bottom: 12px
    font-weight: 500


.sign
    font-size: 36px
    position: relative
    top: 2px
    margin-right: 8px
    +m
        font-size: 30px

.price
    margin-bottom: 12px
    text-align: center
    +body-bold-1
    span
        margin-left: 8px
        color: $n4

.infodesktop
    margin-top: 8px 
    margin-bottom: 20px
    +m
        display: none

.infomobile
    display: none
    margin-top: 20px 
    margin-bottom:10px 
    margin-bottom: 10px
    +m
        display: block