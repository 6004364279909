@import ../../styles/helpers

.form
    font-size: 18px
    max-width: 1280px


.card
    border-radius: 32px
    background: $n7
    +dark
        background: $n2

.input
    border-right: lightgrey 1px solid
    flex: 15 0
    line-height: 1
    height: 64px
    padding: 0 14px 0 14px
    +m
        border-bottom: lightgrey 1px solid
        border-right: none
        flex-grow: 1
        width: 100%
    border-radius: 32px 0px 0px 0px
    background: none
    font-size: 24px
    font-weight: 500
    text-transform: uppercase
    +poppins
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
        font-size: 24px
        font-weight: 500
        +m
            font-size: 18px
            font-weight: 500
    &:focus
        font-size: 24px
    +placeholder
        text-transform: none
        color: $n4
        font-size: 24px
        +m
            font-size: 18px
            font-weight: 500



.searchbar
    position: relative
    display: flex
    width: 100%
    height: 68px
    padding: 0 14px 0 14px
    +m
        display: block
        height: 124px
        padding: 0 6px 0 6px
    border-radius: 30px 30px 0px 0px
    background: none
    border: 2px solid $n6
    +poppins
    +caption-1
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
    &:focus
        border-color: $n4
    +placeholder
        color: $n4

.dropdown
    flex:1

.btn
    &:disabled
        background: #6e97fa
        pointer-events: none
        pointer: default
        &:hover
            background: $n6
    color: $n8
    font-size: 20px
    font-weight: bold
    position: relative
    width: 100%
    height: 58px
    border-radius: 0px 0px 30px 30px
    background: $p1
    transition: background .2s
    svg
        fill: $n8
    &:hover
        background: darken($p1, 10)

.big
    .input
        height: 72px
        padding: 0 72px 0 22px
        border-radius: 36px
        border: 2px solid $n7
        font-size: 16px
        +placeholder
            color: $n5
        &:focus
            border-color: $n5
        +dark
            border-color: $n3
            &:focus
                border-color: $n5
    .btn
        top: 16px
        right: 16px
        bottom: 16px
        width: 40px
        height: 40px
        svg
            width: 20px
            height: 20px