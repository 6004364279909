@import ../../styles/helpers


.success
    padding: 12px
    background: $success
    border-radius: 4px
    text-align: left
    +body-bold-2
    +dark
        background: $success

.warning
    padding: 12px
    background: $warning
    border-radius: 4px
    text-align: left
    +body-bold-2
    +dark
        background: $warning
        
.error
    padding: 12px
    background: $error
    border-radius: 4px
    text-align: left
    +body-bold-2
    +dark
        background: $error

.info
    padding: 12px
    background: $info
    border-radius: 4px
    text-align: left
    +body-bold-2
    +dark
        background: $info