@import ../../styles/helpers

.submitbutton
    margin-top: 24px
    height: 48px
    font-weight: 600
    width: 100%
    background: $p1
    +dark
        background: $p1
    border-radius: 8px
    padding: 0 16px
    font-size: 14px
    color: $n8
    transition: all 0.2s ease
    &:hover
        box-shadow: 0px 4px 8px rgba(15, 15, 15, 0.7)