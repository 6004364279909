@import ../../../styles/helpers


.container
    justify-content: center  
    max-width: 1280px 
    margin: 0 auto !important
    padding: 0 0px !important
    +m
        display: block
        margin: 0 0 !important    
.main
    position: relative
    padding-top: 120px
    overflow: hidden
    +d
        padding-top: 100px
    +m
        padding-top: 32px

.wrap
    flex:10
    position: relative
    text-align: center
    z-index: 3
    justify
    +d
        margin-bottom: 20px
    +m
        margin-bottom: 36px

.form
    +m
        margin-left: 8px
        margin-right: 8px
    margin-bottom: 40px 
    margin-left: auto
    margin-right: auto
    max-width: 700px


.title
    span:nth-child(1)
        color: $p4
    span:nth-child(2)
        color: $p3
    margin-bottom: 20px
    max-width: 1000px
    text-align: center
    margin-left: auto
    margin-right: auto
    +d
        font-size: 66px
    +t
        font-size: 55px
    +m
        font-size: 44px
        margin-bottom: 16px
    +a
        font-size: 40px
    +s
        font-size: 40px

.subtitletext
    margin-top: 12px    
    margin-bottom: 32px
    +body-2
    color: $n4
    text-align: center

.text
    justify-content: center
    margin-left: auto
    margin-right: auto
    margin-bottom: 16px
    +body-2
    color: $n4
    max-width: 700px
    +t
        max-width: 300px

.fontsize
    font-size: 20px !important
    +t
        font-size: 16px    
.button
    margin-bottom: 80px
    +d
        margin-bottom: 0

.scroll
    pointer-events: none
    cursor: none
    +d
        display: none !important
    
.bg
    flex-wrap: wrap
    max-width: 1000px
    display: flex
    justify-content: center
    flex: 5
    margin-left: auto
    margin-right: auto
    margin-bottom: 32px
    pointer-events: none
    padding: 4px
    +d
        padding-left: 20px
    +m

        position: static
        width: auto