// Margin
.mt0
    margin-top: 0px !important
.mt5
    margin-top: 5px !important
.mt10
    margin-top: 10px !important
.mt15
    margin-top: 15px !important
.mt20
    margin-top: 20px !important

.mr0
    margin-right: 0px !important
.mr5
    margin-right: 5px !important
.mr10
    margin-right: 10px !important
.mr15
    margin-right: 15px !important
.mr20
    margin-right: 20px !important


.mb0
    margin-bottom: 0px !important
.mb5
    margin-bottom: 5px !important
.mb10
    margin-bottom: 10px !important
.mb15
    margin-bottom: 15px !important
.mb20
    margin-bottom: 20px !important

.ml0        
    margin-left: 0px !important 
.ml5
    margin-left: 5px !important
.ml10
    margin-left: 10px !important
.ml15
    margin-left: 15px !important
.ml20
    margin-left: 20px !important

// Padding
.pt0
    padding-top: 0px !important
.pt5
    padding-top: 5px !important
.pt10
    padding-top: 10px !important
.pt15
    padding-top: 15px !important
.pt20     
    padding-top: 20px !important

.pr0
    padding-right: 0px !important
.pr5
    padding-right: 5px !important
.pr10
    padding-right: 10px !important
.pr15
    padding-right: 15px !important
.pr20
    padding-right: 20px !important

.pb0
    padding-bottom: 0px !important
.pb5
    padding-bottom: 5px !important
.pb10
    padding-bottom: 10px !important
.pb15
    padding-bottom: 15px !important
.pb20
    padding-bottom: 20px !important

