@import ../../styles/helpers


.card
    max-width: 1000px
    margin: 40px auto
    margin-bottom: 80px
    padding: 32px
    border-radius: 16px
    +m
        border-radius: 0px
    color: $n2
    background: $n7
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    +d
        padding: 24px 16px
    +t
        padding: 16px 0
    +m
        display: flex
        width: 100%
        margin: 0
        padding: 16px 24px
    +dark
        color: $n8
        background: $n2