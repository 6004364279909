@import ../../styles/helpers

.textdivider 
    margin-top: 20px
    margin-bottom: 20px
    display: flex
    color: $n4
    align-items: center


.textdivider::before,
.textdivider::after 
  content: ''
  height: 1px
  background-color: $n6
  +dark
    background-color: $n8
  flex-grow: 1

.textdivider::before 
  margin-right: 10px
  background-color: $n6
  +dark
    background-color: $n3

.textdivider::after 
  margin-left: 10px
  background-color: $n6
  +dark
    background-color: $n3
.divider
    margin-top: 16px
    margin-bottom: 16px
    height: 1px
    background-color: $n6
    +dark
        background-color: $n3
    flex-grow: 1