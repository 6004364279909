@import ../../styles/helpers

.notifications
    padding: 32px 0
    background: $n7
    +t
        padding-bottom: 64px
    +dark
        background: none

.body
    padding: 40px
    border-radius: 8px
    background: $n8
    +t
        padding: 0
        background: none
    +dark
        background: #18191D
        +t
            background: none

.top
    display: flex
    align-items: center
    margin-bottom: 32px
    padding-bottom: 32px
    border-bottom: 1px solid $n6
    +m
        display: block
        margin-bottom: 20px
        padding-bottom: 0
        border: none
    +dark
        border-color: $n3
    .button
        &:not(:last-child)
            margin-right: 16px

.title
    margin-right: auto
    +m
        margin: 0 0 20px

.row
    display: flex
    flex-direction: row-reverse
    +m
        display: block

.toggle
    display: none
    +m
        display: flex
        width: 100%

.wrapper
    flex-grow: 1
    padding-right: 80px
    +d
        padding-right: 48px
    +t
        padding-right: 32px
    +m
        padding: 48px 0 0

.filters
    flex-shrink: 0
    width: 352px
    +x
        width: 300px
    +d
        width: 230px
    +m
        display: none
        padding-top: 32px
        &.active
            display: block

.list
    +m
        margin: 0 -32px

.btns
    margin-top: 56px
    text-align: center
    +t
        margin-top: 32px




.loader,
.loaderWhite
    width: 1em
    height: 1em
    border-radius: 50%
    position: relative
    font-size: 4px
    animation: loader 1.1s infinite ease
    text-indent: -9999em
    transform: translateZ(0)



.loader
    animation: loader 1.1s infinite ease
    +dark
        animation-name: loader-white

.loaderWhite
    animation-name: loader-white


@keyframes loader 
    0%,
    100%
        box-shadow: 0em -2.6em 0em 0em #777e90, 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.5), -1.8em -1.8em 0 0em rgba(119,126,144, 0.7)
    12.5%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.7), 1.8em -1.8em 0 0em #777e90, 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.5)
    25%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.5), 1.8em -1.8em 0 0em rgba(119,126,144, 0.7), 2.5em 0em 0 0em #777e90, 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2)
    37.5%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.5), 2.5em 0em 0 0em rgba(119,126,144, 0.7), 1.75em 1.75em 0 0em #777e90, 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2)
    50%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.5), 1.75em 1.75em 0 0em rgba(119,126,144, 0.7), 0em 2.5em 0 0em #777e90, -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2)
    62.5%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.5), 0em 2.5em 0 0em rgba(119,126,144, 0.7), -1.8em 1.8em 0 0em #777e90, -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2)
    75%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.5), -1.8em 1.8em 0 0em rgba(119,126,144, 0.7), -2.6em 0em 0 0em #777e90, -1.8em -1.8em 0 0em rgba(119,126,144, 0.2)
    87.5%
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.5), -2.6em 0em 0 0em rgba(119,126,144, 0.7), -1.8em -1.8em 0 0em #777e90

@keyframes loader-white
    0%,
    100%
        box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7)
    12.5% 
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5)
    25%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2)
    37.5%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2)
    50%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2)
    62.5%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2)
    75%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2)
    87.5%
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff
